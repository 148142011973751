import React from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"

const StyledHeroContent = styled.div`
display:flex;
width: 350px;
height: 300px;
flex-direction: column;
margin-left: 120px;
margin-top: 30px;
`

const StyledSlogan = styled.div`
font-family: Muli;
font-style: italic;
font-weight: normal;
font-size: 20px;
line-height: 25px;
color: #FFFFFF;
`
const StyledTitle = styled.div`
font-family: Muli;
font-style: normal;
font-weight: bold;
font-size: 27px;
line-height: 34px;
color: #262626;
`

const StyledSubText = styled.div`
font-family: Muli;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
color: #000000;
margin-bottom: 10px;
`
const StyledButton = styled(Link)`
  font-family: Muli;
  text-decoration: none;
  text-align: center;
  font-size: 25px;
  width: 200px;
  height: 60px;
  color: #fff;
  background: transparent;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  transition: all 0.8s ease 0s;
  cursor: pointer;
  &:hover {
    background-color: #fff;
    color: #0a0a0a;
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    font-size: 12px;
    width: 140px;
    height: 30px;
    line-height: 30px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 13px;
    width: 160px;
    height: 30px;
    line-height: 30px;
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 14px;
    width: 160px;
    height: 30px;
    line-height: 30px;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 15px;
    width: 180px;
    height: 35px;
    line-height: 35px;
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    font-size: 16px;
    width: 200px;
    height: 40px;
    line-height: 40px;
  }
`


const HeroContent = () => (
    <StyledHeroContent>
        <StyledSlogan>
        For a Brighter Future
        </StyledSlogan>
        <StyledTitle>
        Excellence in Child Care 
Services  
        </StyledTitle>

        <StyledSubText>
        We take the utmost care in providing every child with professional medical care that will leave a long lasting postive impression.
        </StyledSubText>
        <StyledButton to="/#appointments">
            Book Appointment
        </StyledButton>
    </StyledHeroContent>
)

export default HeroContent