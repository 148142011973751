import React from 'react'
import styled from 'styled-components'
import bg from '../../images/aboutbg.png'

const StyledContainer = styled.div`
height: 600px;
display: flex;
flex-direction: column;
justify-content: flex-start;
padding-top: 60px;
align-items: center;
background-image: linear-gradient(180deg, rgba(38, 38, 38, 0.7) 0%, rgba(38, 38, 38, 0.7) 25.52%, rgba(38, 38, 38, 0.7) 73.5%, #262626 100%), 
url(${bg});, #262626;
background-position: center;
background-repeat: no-repeat;
background-size: cover;
`
const StyledTitle = styled.div`font-family: Muli;
font-style: normal;
margin-bottom: 1.7rem;
font-weight: normal;
font-size: 36px;
line-height: 45px;
color: #FFFFFF;
`

const StyledText = styled.div`
font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 22px;
line-height: 28px;
width: 1000px;
color: #FFFFFF;

`
const AboutUs = () => (
<StyledContainer id="about">
<StyledTitle>About Us</StyledTitle>
<StyledText>
Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt minus facere quis earum? Modi fugit, totam neque aspernatur veritatis est a sequi! Fugiat magni asperiores minima ipsa autem consequuntur nostrum magnam, illum odit possimus, quod dignissimos quidem tenetur veniam impedit dolorum quia adipisci velit officiis ullam accusamus iure cupiditate? Qui amet necessitatibus vero molestias vitae molestiae eaque sunt quia ex ut architecto quam esse placeat accusantium praesentium officia a, reiciendis nihil, tempore saepe recusandae labore ab! Quos veritatis praesentium, aliquam voluptas tempora voluptatibus nihil aperiam eum recusandae iste obcaecati, libero quis quidem! Dignissimos vero ab facere necessitatibus, distinctio, ex praesentium magnam molestiae provident placeat unde sit perspiciatis consectetur ipsa modi. Odit possimus cum minima nemo sed, asperiores quo! Culpa, odio obcaecati qui facere at accusantium libero quidem nostrum, laborum sapiente accusamus eius? Nobis veritatis, iste eligendi quas praesentium voluptatibus, nisi optio esse maiores quisquam deserunt saepe? 
</StyledText>
</StyledContainer>
)

export default AboutUs