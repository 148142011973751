/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"


import styled from "styled-components"
import "./layout.css"
import Header from "./header"
import background from "../images/heroImage.png"
import HeroContent from './HeroContent'
import PhilosophyContent from "./PhilosophyContent"
import AboutUs from './aboutUs'
import Appointments from './Appointments'


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const StyledContainer = styled.div`
display: flex;
flex-direction: column;


`


const StyledHeroContainer = styled.div`
display: flex;
flex: 1;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  background-image:
    url(${background});
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`
const StyledP = styled.p`
font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
color: #fff;
`

const StyledFooter = styled.footer`
display: flex;
justify-content: center;
align-items: flex-end;

height: 165px;

background: #262626;
`
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  return (
    <StyledContainer>

      <StyledHeroContainer>
      <Header siteTitle={data.site.siteMetadata.title} />
      <HeroContent/>
      </StyledHeroContainer>
      <PhilosophyContent/>
      <AboutUs/>
      <Appointments/>
 
 
        <StyledFooter>
          <StyledP>
          Copyright © {new Date().getFullYear()} All Rights Reserved, TMCC
          </StyledP>
    
        </StyledFooter>
   
    </StyledContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
