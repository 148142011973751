import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from "react-spring"

const StyledContainer = styled.div`
 display: flex;
 flex-direction: row;
 height: 550px;
 background: #D6C889;
padding-top: 30px;

`

const StyledSection = styled.div`
flex:1;
display: flex;
flex-direction: column;
align-items: center;

`
const Divider = styled.div`
    height: 70%;
    border: 2px solid #000;
    width: 1px;
    margin-top: 30px;

`

const StyledTitle = styled.div`
font-family: Muli;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 23px;
text-align: center;
color: #262626;
`

const StyledSubTitle = styled.div`

font-family: Muli;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 35px;
padding-top: 60px;
color: #262626;
`

const StyledPhoneNumber = styled.div`

padding-top: 20px;

font-family: Muli;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 45px;

color: #262626;
`

const StyledForm = styled.form`
  font-family: Muli;
  display: flex;
  flex-direction: column;
  padding-top: 30px;

`


const StyledInput = styled.input`

  color: #848484;
  font-family: Muli;
  font-size: 16px;
  padding-left: 10px;
  height: 40px;
  margin-top: 10px;
  margin-bottom : 10px;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    width: 180px;
    font-size: 12px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    width: 200px;
    font-size: 12px;
  }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    width: 300px;
    font-size: 14px;
  }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1600px) {
    width: 400px;   
    font-size: 16px;
  }
`

const StyledTextArea = styled.textarea`
  
color: #848484;
  font-family: Muli;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom : 10px;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    width: 180px;
    height: 80px;
    font-size: 12px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    width: 200px;
    height: 160px;
    font-size: 12px;
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    width: 300px;
    heightL 150px;
    font-size: 14px;
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1600px) {
    width: 400px;
    height: 200px;
    font-size: 16px;
  }
`
const StyledLabel = styled(animated.label)`
  display: inline-block;
  width: 140px;
  color: #848484;
  vertical-align: top;
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 14px;
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1600px) {
    font-size: 18px;
  }
`

const StyledButton = styled.button`
  font-family: Muli;
  text-decoration: none;
  text-align: center;
  width: 200px;
  color: #000;
  background: transparent;
  border: 1px solid #000;

  transition: all 0.8s ease 0s;
  cursor: pointer;
  &:hover {
    background-color: #848484;
    color: #fff;
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    font-size: 12px;
    width: 140px;
    height: 30px;
    line-height: 30px;
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 13px;
    width: 160px;
    height: 30px;
    line-height: 30px;
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 14px;
    width: 160px;
    height: 30px;
    line-height: 30px;
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 15px;
    width: 180px;
    height: 35px;
    line-height: 35px;
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    font-size: 16px;
    width: 200px;
    height: 40px;
    line-height: 30px;
  }
`




const Appointments = ()=> (
<StyledContainer id="appointments">
    <StyledSection><StyledTitle>Book An Appointment by Phone</StyledTitle>
    <StyledSubTitle>
        Regular Visits
    </StyledSubTitle>
    <StyledPhoneNumber>
    987-225-5550
    </StyledPhoneNumber>
    <StyledSubTitle>
        Emergency Hotline
    </StyledSubTitle>
    <StyledPhoneNumber>
    987-225-5550
    </StyledPhoneNumber>
    </StyledSection>
    <Divider/>


    <StyledSection>

    <StyledTitle>Book An Appointment Online</StyledTitle>

    <StyledForm           name="contact"
          method="post"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
          action="/success">

<input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
              <StyledInput type="text" name="name" placeholder="Your Name"/>
              <StyledInput type="email" name="email" placeholder="Your Email"/>
              <StyledInput type="date" name="date" placeholder="Date"/>
              <StyledTextArea placeholder="Special Request"/>
              <StyledButton>Book Appointment</StyledButton>
     
    </StyledForm>
    </StyledSection>
</StyledContainer>
)

export default Appointments