import React from 'react'

import styled from 'styled-components'

import image1 from '../../images/image1.png'
import image2 from '../../images/image2.png'
import image3 from '../../images/image3.png'
const StyledContainer = styled.div`
display: flex;
height: 450px;
flex-direction: row;
`
const StyledSection = styled.div`
padding-top: 60px;

    display:flex;
    flex:1;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    
`
const StyledImage = styled.img`
width: 135px;
height: 135px;

`
const StyledTitle = styled.div`
font-family: Muli;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 20px;
color: #000000;
`

const StyledSubTextbox = styled.div`
margin-top: 5px;
width: 200px;
height: 130px;
`
const StyledSubText = styled.div`
font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 18px;
text-align: center;
color: #595656;
`
const PhilosophyContent =  () => (

<StyledContainer>
    <StyledSection><StyledImage src={image1}></StyledImage>
    
    <StyledTitle>
        Quality Care</StyledTitle>
        <StyledSubTextbox>
        <StyledSubText>
        At TMCC, we strive to provide the highest level of pediactric childcare services. Each individual case is dealt with the highest level of professionalism and care.
        </StyledSubText>
        </StyledSubTextbox>
        </StyledSection>
    <StyledSection>
        <StyledImage src={image2}></StyledImage>
        <StyledTitle>
            Child Friendly Environment
        </StyledTitle>

        <StyledSubTextbox>
            <StyledSubText>
            Our clinic facility is designed to not only provide the best care but to also make our precious little clients comfortable and happy. Play structures, toys, candies are not optional!
            </StyledSubText>
        </StyledSubTextbox>
        </StyledSection>
    <StyledSection>
        
        <StyledImage src={image3}></StyledImage>
        <StyledTitle>
            Friendly Staff
        </StyledTitle>

        <StyledSubTextbox>
            <StyledSubText>
            The staff at TMCC are trained for the highest level of customer care and satisfaction. We take your feedback very seriously in improving our services.
            </StyledSubText>
        </StyledSubTextbox>
        </StyledSection>
</StyledContainer>
)

export default PhilosophyContent