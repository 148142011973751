import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import styled from 'styled-components'
import logo from "../images/logo.png"
const StyledHeader = styled.header`
  background: transparent;
  display: flex;
  height: 100px;
  width: 100%;
  padding: 10px;
  flex-direction: row;
  padding-left: 30px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 30px;
`
const StyledMenu = styled.div`
display : flex;
flex-direction: row;
width: 50%;
justify-content: flex-end;
margin-top: -20px;

`
const StyledLogo = styled.img`

  height: 70px;
  width: 70px;
 
`
const StyledTitle = styled.p`
margin-left: 10px;
font-family: Muli;
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 30px;
`


const StyledLink = styled(Link)`
font-family: Muli;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 16px;
  color: #ffffff;
  margin-left: 30px;
  transition: all 0.8s ease 0s;
  text-decoration: none;
  &:hover {
    padding: 5px 10px 5px 10px;
    background-color: #fff;
    color: #0a0a0a;
  }
`
const Header = ({ siteTitle }) => (
  <StyledHeader
  >
    <StyledLogo src={logo}></StyledLogo>

      <StyledTitle>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </StyledTitle>
          <StyledMenu>
            <StyledLink to="/" >Home</StyledLink>
            <StyledLink to="/#about">About</StyledLink>
            <StyledLink to="/#appointments">Appointments</StyledLink>
          </StyledMenu>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
